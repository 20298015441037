import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './web/App';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Language Switch
import './i18n.js';

// Global Style Sheet
import './design/index.css';
import setAuthToken from './shared/utils/setAuthToken';
import { store } from './store/store';
import axiosInterceptors from './utils/axiosInterceptors.js';

if (localStorage.theToken) setAuthToken(localStorage.theToken);
else if (localStorage.theTokenTemp) setAuthToken(localStorage.theTokenTemp);

// Create a client
const queryClient = new QueryClient();

// Initialize Axios interceptors
axiosInterceptors();

// Sentry
if (import.meta.env.VITE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://7d011f507f882338329306347d43322e@sentry.vaccineledger.com/3',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

// Application Root
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <React.Suspense fallback='Loading...'>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </React.Suspense>
    </Provider>
  </React.StrictMode>
);
